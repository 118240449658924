export default defineNuxtRouteMiddleware((_to, from) => {
  const user = useStrapiUser()
  const redirectAfterLogin = from.meta?.redirectAfterLogin

  if (!user.value) {
    if (redirectAfterLogin) {
      return navigateTo({
        name: 'login',
        query: { redirectAfterLogin: from.fullPath },
      })
    }
    return navigateTo({ name: 'login' })
  }
})
